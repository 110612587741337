import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import AccessibleExternalCarePlanActionMenuPortal from "./AccessibleExternalCarePlanActionMenuPortal"
import CarePlanActionBar from "./CarePlanActionBar"
import CarePlanActionMenuPortal from "./CarePlanActionMenuPortal"
import NeedsList from "./NeedsList"

import { ConfirmationModalProvider } from "~/design/modals/useConfirmationModal"
import { FeatureFlagsProvider } from "~/featureFlags"
import FeatureFlag from "~/featureFlags/FeatureFlag"
import { ToastContainer } from "~/toast"
import types from "~/types"
import CareSuggestionNotifications from "~/views/carePlans/CareSuggestionNotifications"
import MedicalBackgroundWidget from "~/views/shared/MedicalBackgroundWidget"
import SocialBackgroundWidget from "~/views/shared/SocialBackgroundWidget"

function CarePlanBody({
  showSidebar,
  isAccessibleExternal,
  chart,
  medicalBackground,
  socialBackground,
  careSuggestionNotifications,
  carePlanSuggestionsFeatureEnabled,
}) {
  return (
    <ConfirmationModalProvider>
      <div className="care-plan-body">
        {isAccessibleExternal ? <AccessibleExternalCarePlanActionMenuPortal /> : <CarePlanActionMenuPortal />}
        {showSidebar ? (
          <>
            <Row>
              <Col md={{ offset: 3, span: 9 }}>
                <CarePlanActionBar />
              </Col>
            </Row>
            <Row data-testid="care-plan-sidebar">
              <Col md={3}>
                <div>
                  <SocialBackgroundWidget chart={chart} socialBackground={socialBackground} />
                  <MedicalBackgroundWidget chart={chart} medicalBackground={medicalBackground} />
                </div>
              </Col>
              <Col md={9}>
                <NeedsList />
                <FeatureFlagsProvider userEnabledFeatures={{ carePlanSuggestions: carePlanSuggestionsFeatureEnabled }}>
                  <FeatureFlag flag="carePlanSuggestions">
                    <CareSuggestionNotifications careSuggestionNotifications={careSuggestionNotifications} />
                  </FeatureFlag>
                </FeatureFlagsProvider>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <CarePlanActionBar />
            <NeedsList />
          </>
        )}
        <ToastContainer />
      </div>
    </ConfirmationModalProvider>
  )
}

CarePlanBody.propTypes = {
  showSidebar: PropTypes.bool.isRequired,
  isAccessibleExternal: PropTypes.bool.isRequired,
  chart: types.chart,
  medicalBackground: types.medicalBackground,
  socialBackground: types.socialBackground,
  careSuggestionNotifications: PropTypes.arrayOf(types.careSuggestionNotification),
  carePlanSuggestionsFeatureEnabled: PropTypes.bool.isRequired,
}

export default connect(({ globals: { isAccessibleExternal, showSidebar } }) => ({
  isAccessibleExternal,
  showSidebar,
}))(CarePlanBody)
