import { Dropdown, DropdownButton } from "react-bootstrap"
import ReactDOM from "react-dom"
import { connect } from "react-redux"
import _ from "lodash"

import { ActiveOnlyFilterState } from "~/components/carePlans/carePlanActiveFilterActions"
import { getActiveFilterState, getFilteredNeedCategoryId } from "~/components/carePlans/carePlanUISelectors"
import {
  api_v1_care_plan_clinical_pdf_exports_path,
  api_v1_care_plan_patient_pdf_exports_path,
  new_care_plan_care_plan_signature_path,
} from "~/routes"

function removeFalsyValues(obj) {
  return _.pickBy(obj, Boolean)
}

function CarePlanActionMenuPortal({ carePlan, activeFilterState, filteredNeedCategoryId }) {
  const node = document.getElementById("care-plan-print-buttons-portal-selector")

  const filterParams = removeFalsyValues({
    active_only: activeFilterState === ActiveOnlyFilterState.ACTIVE_ONLY,
    care_plan_need_category_id: filteredNeedCategoryId,
  })

  return ReactDOM.createPortal(
    <DropdownButton variant="outline-primary" title="Actions" menuAlign="right">
      <Dropdown.Item
        as="button"
        data-target="_blank"
        data-trigger-download-modal-async={api_v1_care_plan_clinical_pdf_exports_path({
          care_plan_id: carePlan.id,
          filters: filterParams,
        })}
      >
        View Clinical Version PDF
      </Dropdown.Item>
      <Dropdown.Item
        as="button"
        data-target="_blank"
        data-trigger-download-modal-async={api_v1_care_plan_patient_pdf_exports_path({
          care_plan_id: carePlan.id,
          filters: filterParams,
        })}
      >
        View Patient Version PDF
      </Dropdown.Item>
      <Dropdown.Item href={new_care_plan_care_plan_signature_path(carePlan.id, { version: "Clinical" })}>
        E-sign Clinical Version
      </Dropdown.Item>
      <Dropdown.Item href={new_care_plan_care_plan_signature_path(carePlan.id, { version: "Patient" })}>
        E-sign Patient Version
      </Dropdown.Item>
    </DropdownButton>,
    node
  )
}

export default connect((state) => ({
  activeFilterState: getActiveFilterState(state),
  carePlan: state.entities.carePlan,
  filteredNeedCategoryId: getFilteredNeedCategoryId(state),
}))(CarePlanActionMenuPortal)
