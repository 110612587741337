import { Col, Row } from "react-bootstrap"
import PropTypes from "prop-types"

import { LineDivider } from "~/components/Divider"
import Form from "~/design/forms"
import types from "~/types"

function MemberFormFields({ preferredContactMethods }) {
  return (
    <>
      <Row>
        <Col md={6}>
          <Form.Group clearOnHide={false} name="memberAttributes.firstName" errorKey="member.firstName">
            <Form.Label>First Name</Form.Label>
            <Form.TextField />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group clearOnHide={false} name="memberAttributes.lastName" errorKey="member.lastName">
            <Form.Label>Last Name</Form.Label>
            <Form.TextField />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group clearOnHide={false} name="memberAttributes.strength" errorKey="member.strength">
            <Form.Label>Strength</Form.Label>
            <Form.TextField placeholder="Example: Podiatry" />
          </Form.Group>
        </Col>
      </Row>
      <LineDivider />
      <Row>
        <Col>
          <Form.Group clearOnHide={false} name="memberAttributes.otherNotes" errorKey="member.otherNotes">
            <Form.Label>Other Notes</Form.Label>
            <Form.TextArea />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group clearOnHide={false} name="memberAttributes.phone" errorKey="member.phone">
            <Form.Label>Phone</Form.Label>
            <Form.TextField placeholder="Include country/area code" />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group clearOnHide={false} name="memberAttributes.address" errorKey="member.address">
            <Form.Label>Address</Form.Label>
            <Form.TextField placeholder="Include street, city, state, and zip" />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group clearOnHide={false} name="memberAttributes.email" errorKey="member.email">
            <Form.Label>Email</Form.Label>
            <Form.TextField placeholder="Example: jacob@example.com" />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group name="memberAttributes.preferredContactMethodId" errorKey="member.preferredContactMethodId">
            <Form.Label>Preferred Contact Method</Form.Label>
            <Form.SelectBox options={preferredContactMethods} />
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

MemberFormFields.propTypes = {
  preferredContactMethods: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default MemberFormFields
