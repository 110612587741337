import { useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import SideBarList from "../SideBarList"
import SideBarToggle from "../SideBarToggle"

import AddMembershipForm from "./AddMembershipForm"
import EditMembershipForm from "./EditMembershipForm"
import useMembershipModal from "./useMembershipModal"

import { IconButton } from "~/design/buttons"
import { AddIcon, DeleteIcon, EditIcon } from "~/design/icons"
import useConfirmationModal from "~/design/modals/useConfirmationModal"
import { chart_memberships_path } from "~/routes"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function FamilyAndCommunityTeamMembershipSection({
  memberships: initialMemberships,
  chart,
  relationships,
  preferredContactMethods,
}) {
  const [memberships, setMemberships] = useState(initialMemberships)

  const { selectedMembership, isAddModalVisible, isEditModalVisible, showAddModal, showEditModal, hideModal } =
    useMembershipModal()

  function handleAdd(membership) {
    setMemberships((careTeamMemberships) => [...careTeamMemberships, membership])
  }
  const confirm = useConfirmationModal()

  async function handleDelete(membershipId) {
    const confirmed = await confirm.danger(
      `Remove this member from the family and community team? If the membership is no longer active,
      please don't remove them — mark the membership 'Inactive' instead by clicking 'Edit.' If the membership was
      mistakenly added to family and community team, click 'Remove' to remove them.`,
      { confirmButtonLabel: "Remove" }
    )

    if (confirmed) {
      try {
        await api.familyAndCommunityTeamMemberships.delete(membershipId)
        toast.success("Family and Community team member successfully removed.")
        setMemberships((memberships) => memberships.filter((membership) => membership.id !== membershipId))
      } catch {
        toast.error(
          <p>
            Care team member could not be removed.
            <br />
            Please refresh the page.
          </p>
        )
      }
    }
  }

  function handleUpdate(updatedMembership) {
    setMemberships((careTeamMemberships) =>
      careTeamMemberships.map((membership) => (membership.id === updatedMembership.id ? updatedMembership : membership))
    )
  }

  function membershipRelation(membership) {
    return `${membership.relationshipCategory} - ${membership.relationshipName}`
  }

  const activeCareTeamMemberships = memberships.filter((membership) => membership.active)

  return (
    <>
      <SideBarToggle
        title="Family and Community"
        ActionIcon={AddIcon}
        actionLabel="Add Family and Community Team member"
        onActionIconClick={showAddModal}
      >
        <SideBarList emptyListMessage="This patient does not have any active family and community team members.">
          {activeCareTeamMemberships.map((membership, index) => (
            <li
              key={membership.id}
              className={classNames("py-2", { "border-bottom": index < activeCareTeamMemberships.length - 1 })}
            >
              <div>
                <div className="d-flex justify-content-between">
                  <span className="small text-uppercase">{membershipRelation(membership)}</span>
                  <span>
                    <IconButton
                      Icon={EditIcon}
                      label="Edit family and community team membership"
                      size="sm"
                      onClick={() => showEditModal(membership)}
                    />
                    <IconButton
                      Icon={DeleteIcon}
                      label="Delete family and community team membership"
                      size="sm"
                      onClick={() => handleDelete(membership.id)}
                    />
                  </span>
                </div>
                <h4 className="font-weight-bold mb-2">{membership.member.name}</h4>
                <p className="small m-0">Strength: {membership.member.strength}</p>
                <p className="small m-0">{membership.member.phone}</p>
                <p className="small m-0">{membership.member.address}</p>
              </div>
            </li>
          ))}
        </SideBarList>
        <a href={chart_memberships_path(chart.id)}>View inactive family and community team members</a>
      </SideBarToggle>
      {selectedMembership ? (
        <EditMembershipForm
          membership={selectedMembership}
          show={isEditModalVisible}
          onClose={hideModal}
          onUpdate={handleUpdate}
          preferredContactMethods={preferredContactMethods}
          relationships={relationships}
        />
      ) : null}
      <AddMembershipForm
        chart={chart}
        relationships={relationships}
        preferredContactMethods={preferredContactMethods}
        show={isAddModalVisible}
        onAdd={handleAdd}
        onClose={hideModal}
      />
    </>
  )
}

FamilyAndCommunityTeamMembershipSection.propTypes = {
  memberships: PropTypes.arrayOf(types.familyAndCommunityTeamMembership).isRequired,
  chart: types.chart,
  relationships: PropTypes.arrayOf(types.selectGroupedOptions).isRequired,
  preferredContactMethods: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default FamilyAndCommunityTeamMembershipSection
