import { useEffect } from "react"

import ListGroup from "~/components/listGroup"

function SideBar() {
  const SIDEBAR_TOP_OFFSET = 20

  useEffect(() => {
    // The offset controls where the scrollspy thinks the boundary for the section begins
    // relative to the top of the page.
    $("body").scrollspy({ offset: SIDEBAR_TOP_OFFSET + 5, target: "#response-sidebar" })
  }, [])

  return (
    <div id="response-sidebar" style={{ position: "sticky", top: `${SIDEBAR_TOP_OFFSET}px` }}>
      <h3 className="mb-2">Sections</h3>
      <ListGroup size="sm">
        <ListGroup.Item action href="#hfw-cftm-child-and-family-team-meeting">
          Child and Family Team Meeting
        </ListGroup.Item>
        <ListGroup.Item action href="#hfw-cftm-section-family-vision">
          Family Vision
        </ListGroup.Item>
        <ListGroup.Item action href="#hfw-cftm-section-underlying-needs">
          Underlying Needs
        </ListGroup.Item>
        <ListGroup.Item action href="#hfw-cftm-section-school-and-work">
          School and Work
        </ListGroup.Item>
        <ListGroup.Item action href="#hfw-cftm-section-complete">
          Complete
        </ListGroup.Item>
      </ListGroup>
    </div>
  )
}

export default SideBar
