import { useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { questionCreated } from "../redux/questionsSlice"

import Card from "./Card"
import CareSuggestionLinkingButton from "./CareSuggestionLinkingButton"
import SchemaQuestionFields from "./SchemaQuestionFields"

import { BulletDivider } from "~/components/Divider"
import { LinkButton } from "~/design/buttons"
import Form from "~/design/forms"
import FeatureFlag from "~/featureFlags/FeatureFlag"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function NewSchemaQuestionForm({
  onFormClose,
  questionIndex,
  questionTypes,
  questionCreatedConnect,
  sectionId,
  healthHomeId,
}) {
  const [isDependsOnQuestionCardOpen, setIsDependsOnQuestionCardOpen] = useState(false)
  const defaultQuestion = {
    questionText: "",
    questionTypeId: questionTypes[0].value,
    isRequired: false,
    optionsAttributes: [],
    sectionId,
  }
  const [isCareSuggestionLinkingSurfaced, setIsCareSuggestionLinkingSurfaced] = useState(false)

  async function handleSubmit(questionFormData, { setErrors, setStatus }) {
    try {
      const createdQuestion = await api.customAssessments.questions.post(sectionId, questionFormData)
      questionCreatedConnect(createdQuestion)

      toast.success("Question created successfully.")
      setStatus("success")
      onFormClose()
    } catch ({ errors }) {
      toast.error("Question could not be created.")
      setErrors(errors)
    }
  }

  return (
    <Form.Provider
      initialValues={defaultQuestion}
      onSubmit={handleSubmit}
      formId={`schema-section-${sectionId}-new-question`}
    >
      <Card dataTestid={`schema-question-card-new`}>
        <Card.Header title={`New Question ${questionIndex + 1}`}>
          <FeatureFlag flag="carePlanSuggestions">
            <CareSuggestionLinkingButton
              isCareSuggestionLinkingSurfaced={isCareSuggestionLinkingSurfaced}
              onClick={() => setIsCareSuggestionLinkingSurfaced(!isCareSuggestionLinkingSurfaced)}
              setIsCareSuggestionLinkingSurfaced={setIsCareSuggestionLinkingSurfaced}
            />
          </FeatureFlag>
          <LinkButton disabled={isDependsOnQuestionCardOpen} onClick={() => setIsDependsOnQuestionCardOpen(true)}>
            Make Dependent
          </LinkButton>
          <BulletDivider />
          <LinkButton color="danger" onClick={onFormClose}>
            Remove
          </LinkButton>
        </Card.Header>
        <Card.Body>
          <Form.Body vertical>
            <SchemaQuestionFields
              isDependsOnQuestionCardOpen={isDependsOnQuestionCardOpen}
              questionIndex={questionIndex}
              questionTypes={questionTypes}
              onCloseDependsOnQuestionCard={() => setIsDependsOnQuestionCardOpen(false)}
              isCareSuggestionLinkingSurfaced={isCareSuggestionLinkingSurfaced}
              healthHomeId={healthHomeId}
            />
          </Form.Body>
        </Card.Body>
        <Card.Footer right={<Form.SubmitButton label="Create" />} />
      </Card>
    </Form.Provider>
  )
}

NewSchemaQuestionForm.propTypes = {
  onFormClose: PropTypes.func.isRequired,
  questionIndex: PropTypes.number.isRequired,
  questionTypes: PropTypes.arrayOf(types.selectOption),
  questionCreatedConnect: PropTypes.func.isRequired,
  sectionId: PropTypes.number.isRequired,
  healthHomeId: PropTypes.number.isRequired,
}

export default connect(({ questionTypes }) => ({ questionTypes }), {
  questionCreatedConnect: questionCreated,
})(NewSchemaQuestionForm)
