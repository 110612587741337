import { submitAsyncRequest } from "~/asyncHelpers"
import {
  api_v1_chart_harp_hcbs_eligibility_assessments_path,
  api_v1_harp_hcbs_eligibility_assessment_path,
} from "~/routes"

const harp = {
  hcbsEligibilityAssessments: {
    post: function (chartId, harpHcbsEligibilityAssessment) {
      return submitAsyncRequest({
        url: api_v1_chart_harp_hcbs_eligibility_assessments_path(chartId),
        type: "post",
        data: { harpHcbsEligibilityAssessment },
      })
    },
    patch: function (id, harpHcbsEligibilityAssessment) {
      return submitAsyncRequest({
        url: api_v1_harp_hcbs_eligibility_assessment_path(id),
        type: "patch",
        data: { harpHcbsEligibilityAssessment },
        keyTransformOverrides: { _destroy: "_destroy" },
      })
    },
    delete: function (id) {
      return submitAsyncRequest({
        url: api_v1_harp_hcbs_eligibility_assessment_path(id),
        type: "delete",
      })
    },
  },
}

export default harp
