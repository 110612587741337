import { Col, Row } from "react-bootstrap"
import PropTypes from "prop-types"

import MemberFormFields from "./MemberFormFields"
import OtherDetailsSection from "./OtherDetailsSection"

import Form from "~/design/forms"
import Modal from "~/design/Modal"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function AddMembershipForm({ chart, preferredContactMethods, relationships, show, onAdd, onClose }) {
  const initialValues = {
    active: true,
    startDate: "",
    endDate: "",
    memberAttributes: {
      firstName: "",
      lastName: "",
      strength: "",
      otherNotes: "",
      phone: "",
      address: "",
      email: "",
      preferredContactMethod: null,
    },
    relationshipId: null,
    otherRelationshipDetails: "",
    chartId: chart.id,
  }

  async function onSubmit(formData, { setErrors, setStatus }) {
    try {
      const membership = await api.familyAndCommunityTeamMemberships.post(chart.id, formData)
      onAdd(membership)
      toast.success("Family and Community team member added.")
      setStatus("success")
      onClose()
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Modal
      show={show}
      onClose={onClose}
      title="Add Family And Community Team Member"
      size="xl"
      dataTestId="add-family-and-community-team-membership-modal-form"
    >
      <Form.Provider
        formId="add-family-and-community-team-membership-modal-form"
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        <Modal.Body>
          <Form.Body vertical>
            <Row>
              <Col md={6}>
                <MemberFormFields preferredContactMethods={preferredContactMethods} />
              </Col>
              <Col md={6}>
                <Form.Group name="relationshipId">
                  <Form.Label>Relationship</Form.Label>
                  <Form.SelectBox options={relationships} />
                </Form.Group>
                <OtherDetailsSection relationships={relationships} />
                <Form.Group name="startDate">
                  <Form.Label>Start</Form.Label>
                  <Form.DatePicker />
                </Form.Group>
                <Form.Group name="endDate">
                  <Form.Label>End</Form.Label>
                  <Form.DatePicker />
                </Form.Group>
                <Form.Group name="active">
                  <Form.CheckBox label="Active" />
                </Form.Group>
              </Col>
            </Row>
          </Form.Body>
        </Modal.Body>
        <Modal.Footer>
          <Form.CancelButton onClick={onClose} />
          <Form.SubmitButton label="Save" />
        </Modal.Footer>
      </Form.Provider>
    </Modal>
  )
}

AddMembershipForm.propTypes = {
  chart: types.chart.isRequired,
  onAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  relationships: PropTypes.arrayOf(types.selectGroupedOptions).isRequired,
  preferredContactMethods: PropTypes.arrayOf(types.selectOption).isRequired,
  show: PropTypes.bool.isRequired,
}

export default AddMembershipForm
