import { submitAsyncRequest } from "~/asyncHelpers"
import {
  api_v1_family_and_community_team_membership_path,
  api_v1_family_and_community_team_memberships_path,
} from "~/routes"

const familyAndCommunityTeamMemberships = {
  post: function (chartId, params) {
    return submitAsyncRequest({
      url: api_v1_family_and_community_team_memberships_path(),
      type: "post",
      data: { chartId, membership: params },
    })
  },
  patch: function (membershipId, params) {
    return submitAsyncRequest({
      url: api_v1_family_and_community_team_membership_path(membershipId),
      type: "patch",
      data: { membership: params },
    })
  },
  delete: function (membershipId) {
    return submitAsyncRequest({
      url: api_v1_family_and_community_team_membership_path(membershipId),
      type: "delete",
    })
  },
}

export default familyAndCommunityTeamMemberships
