import PropTypes from "prop-types"

import CareSuggestionNotification from "./CareSuggestionNotification"

import types from "~/types"

function CareSuggestionNotifications({ careSuggestionNotifications: initialCareSuggestionNotifications }) {
  const careSuggestionNotifications = initialCareSuggestionNotifications

  return (
    // id must stay consistent with the care_suggestions_jump_link_id in app/views/patients/care_plans/show.html.haml
    <div id="care-suggestion-notifications">
      {careSuggestionNotifications.map((careSuggestionNotification) => (
        <CareSuggestionNotification careSuggestionNotification={careSuggestionNotification} />
      ))}
    </div>
  )
}

CareSuggestionNotifications.propTypes = {
  careSuggestionNotifications: PropTypes.arrayOf(types.careSuggestionNotification).isRequired,
}

export default CareSuggestionNotifications
