import { Table } from "react-bootstrap"
import PropTypes from "prop-types"

import * as customAssessmentResponseTypes from "../types"

import { WarningAlert } from "~/design/alerts"

function LinkedCareSuggestions({ options, questionNumber }) {
  return (
    <>
      <WarningAlert>
        Linked Care Suggestions: These linked suggestions will not be visible on the assessment.
      </WarningAlert>
      <Table
        striped
        bordered
        className="mb-4 fcm-table"
        data-testid={`question-${questionNumber}-linked-care-suggestions`}
      >
        <thead>
          <tr>
            <th className="col-6">Answer</th>
            <th className="col-6">Care Suggestion</th>
          </tr>
        </thead>
        <tbody>
          {options.map((option) => {
            if (option.careSuggestions.length > 0) {
              return (
                <tr key={option.id}>
                  <td className="align-top">{option.optionText}</td>
                  <td>
                    {option.careSuggestions.length > 1 ? (
                      <ul className="mb-0">
                        {option.careSuggestions.map((suggestion) => {
                          return <li key={suggestion.id}>{suggestion.text}</li>
                        })}
                      </ul>
                    ) : (
                      <>{option.careSuggestions[0].text}</>
                    )}
                  </td>
                </tr>
              )
            } else {
              return null
            }
          })}
        </tbody>
      </Table>
    </>
  )
}

LinkedCareSuggestions.propTypes = {
  options: PropTypes.arrayOf(customAssessmentResponseTypes.schemaQuestionOption).isRequired,
  questionNumber: PropTypes.number.isRequired,
}

export default LinkedCareSuggestions
