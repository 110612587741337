import { Col, Row } from "react-bootstrap"
import PropTypes from "prop-types"

import MemberFormFields from "./MemberFormFields"
import OtherDetailsSection from "./OtherDetailsSection"

import Form from "~/design/forms"
import Modal from "~/design/Modal"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function EditMembershipForm({ membership, preferredContactMethods, relationships, onClose, onUpdate, show }) {
  async function onSubmit(formData, { setErrors, setStatus }) {
    try {
      const response = await api.familyAndCommunityTeamMemberships.patch(membership.id, formData)
      onUpdate(response)
      toast.success("Family and Community team member updated.")
      setStatus("success")
      onClose()
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Modal
      show={show}
      onClose={onClose}
      title="Edit Family And Community Team Member"
      size="xl"
      dataTestId="edit-family-and-community-team-membership-modal-form"
    >
      <Form.Provider
        formId="edit-family-and-community-team-membership-modal-form"
        initialValues={{ ...membership, memberAttributes: membership.member }}
        onSubmit={onSubmit}
      >
        <Modal.Body>
          <Form.Body vertical>
            <Row>
              <Col md={6}>
                <MemberFormFields preferredContactMethods={preferredContactMethods} />
              </Col>
              <Col md={6}>
                <Form.Group name="relationshipId">
                  <Form.Label>Relationship</Form.Label>
                  <Form.SelectBox options={relationships} />
                </Form.Group>
                <OtherDetailsSection relationships={relationships} />
                <Form.Group name="startDate">
                  <Form.Label>Start</Form.Label>
                  <Form.DatePicker />
                </Form.Group>
                <Form.Group name="endDate">
                  <Form.Label>End</Form.Label>
                  <Form.DatePicker />
                </Form.Group>
                <Form.Group name="active">
                  <Form.CheckBox label="Active" />
                </Form.Group>
              </Col>
            </Row>
          </Form.Body>
        </Modal.Body>
        <Modal.Footer>
          <Form.CancelButton onClick={onClose} />
          <Form.SubmitButton label="Save" />
        </Modal.Footer>
      </Form.Provider>
    </Modal>
  )
}

EditMembershipForm.propTypes = {
  membership: types.familyAndCommunityTeamMembership.isRequired,
  relationships: PropTypes.arrayOf(types.selectGroupedOptions).isRequired,
  preferredContactMethods: PropTypes.arrayOf(types.selectOption).isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

export default EditMembershipForm
