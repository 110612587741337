import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_care_suggestion_archivals_path, api_v1_care_suggestions_path } from "~/routes"

const careSuggestions = {
  get: function (healthHomeId) {
    return submitAsyncRequest({
      url: api_v1_care_suggestions_path({ health_home_id: healthHomeId }),
      type: "get",
    })
  },
  post: function (careSuggestion) {
    return submitAsyncRequest({
      url: api_v1_care_suggestions_path(),
      type: "post",
      data: { careSuggestion },
    })
  },
  patch: function (careSuggestionId) {
    return submitAsyncRequest({
      url: api_v1_care_suggestion_archivals_path(careSuggestionId),
      type: "patch",
    })
  },
}

export default careSuggestions
