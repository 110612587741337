const DATA_SELECTOR = "[data-behavior=masked-text-field]"

class MaskedTextField {
  static visibleIconClass = "fa-eye"
  static hiddenIconClass = "fa-eye-slash"

  constructor(element) {
    this.target = element.querySelector("input[data-behavior=masked-text-field-target]")
    this.trigger = element.querySelector("[data-behavior=masked-text-field-trigger]")
    this.icon = element.querySelector("[data-behavior=masked-text-field-icon]")

    this.listen()
  }

  listen() {
    this.trigger.addEventListener("click", this.toggle.bind(this))
  }

  toggle() {
    if (this.target.type === "password") {
      this.target.type = "text"
      this.icon.classList.replace(MaskedTextField.visibleIconClass, MaskedTextField.hiddenIconClass)
    } else {
      this.target.type = "password"
      this.icon.classList.replace(MaskedTextField.hiddenIconClass, MaskedTextField.visibleIconClass)
    }
  }
}

document.addEventListener("DOMContentLoaded", () => {
  for (const element of document.querySelectorAll(DATA_SELECTOR)) {
    new MaskedTextField(element)
  }
})
