import { useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import { isSelectQuestionType } from "../../responses/helpers/questionHelpers"

import SchemaQuestionCardDependsOnQuestionFields from "./SchemaQuestionCardDependsOnQuestionFields"
import SchemaQuestionCardOptionsSection from "./SchemaQuestionCardOptionsSection"

import { LineDivider } from "~/components/Divider"
import Form from "~/design/forms"
import Show from "~/design/Show"
import types from "~/types"

function SchemaQuestionFields({
  isDependsOnQuestionCardOpen,
  questionIndex,
  questionTypes,
  onCloseDependsOnQuestionCard,
  healthHomeId,
  isCareSuggestionLinkingSurfaced,
}) {
  const { values: question, setFieldValue } = useFormikContext()
  const { questionTypeId } = question

  // Clear depends on question when depends on question card is closed
  useEffect(() => {
    if (!isDependsOnQuestionCardOpen) {
      setFieldValue("dependsOnQuestionId", null)
      setFieldValue("dependsOnQuestionOptionIds", [])
    }
  }, [isDependsOnQuestionCardOpen, setFieldValue])

  // Clear options when a non-select question type is selected
  useEffect(() => {
    if (!isSelectQuestionType(questionTypeId)) {
      setFieldValue("options", [])
    }
  }, [questionTypeId, setFieldValue])

  return (
    <>
      <Row className="align-items-start">
        <Col md={7}>
          <Form.Group name="questionText">
            <Form.Label>Question Text</Form.Label>
            <Form.TextField placeholder={`Question ${questionIndex + 1}`} />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group name="questionTypeId" errorKey="questionType">
            <Form.Label>Field Type</Form.Label>
            <Form.SelectBox options={questionTypes} />
          </Form.Group>
        </Col>
        <Col md={2}>
          <div className="mt-3 pt-3">
            <Form.Group name="isRequired">
              <Form.CheckBox label="Required" />
            </Form.Group>
          </div>
        </Col>
      </Row>
      <Show when={isSelectQuestionType(questionTypeId)}>
        <LineDivider />
        <SchemaQuestionCardOptionsSection
          questionIndex={questionIndex}
          healthHomeId={healthHomeId}
          isCareSuggestionLinkingSurfaced={isCareSuggestionLinkingSurfaced}
        />
      </Show>
      <Show when={isDependsOnQuestionCardOpen}>
        <SchemaQuestionCardDependsOnQuestionFields question={question} onClose={onCloseDependsOnQuestionCard} />
      </Show>
    </>
  )
}

SchemaQuestionFields.propTypes = {
  isDependsOnQuestionCardOpen: PropTypes.bool.isRequired,
  questionIndex: PropTypes.number.isRequired,
  questionTypes: PropTypes.arrayOf(types.selectOption).isRequired,
  onCloseDependsOnQuestionCard: PropTypes.func.isRequired,
  healthHomeId: PropTypes.number.isRequired,
  isCareSuggestionLinkingSurfaced: PropTypes.bool.isRequired,
}

export default SchemaQuestionFields
