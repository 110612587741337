import PropTypes from "prop-types"

const harpHcbsEligibilityAssessment = PropTypes.shape({
  id: PropTypes.number,
  date: PropTypes.string,
  resultId: PropTypes.number,
  hasActiveClaim: PropTypes.bool,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      file: PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
      }),
      kindId: PropTypes.number,
    })
  ),
})

export default harpHcbsEligibilityAssessment
