const DATA_SELECTOR = "[data-behavior=masked-text]"

class MaskedText {
  static visibleIconClass = "fa-eye"
  static hiddenIconClass = "fa-eye-slash"

  constructor(element) {
    this.target = element.querySelector("[data-behavior=masked-text-target]")
    this.trigger = element.querySelector("[data-behavior=masked-text-trigger]")
    this.icon = element.querySelector("[data-behavior=masked-text-icon]")

    this.masked = true
    this.text = this.target.dataset.text
    this.maskedText = this.text.replaceAll(/\w/g, "•")

    this.listen()
  }

  listen() {
    this.trigger.addEventListener("click", this.toggle.bind(this))
  }

  toggle() {
    if (this.masked) {
      this.masked = false
      this.target.textContent = this.text
      this.icon.classList.replace(MaskedText.visibleIconClass, MaskedText.hiddenIconClass)
    } else {
      this.masked = true
      this.target.textContent = this.maskedText
      this.icon.classList.replace(MaskedText.hiddenIconClass, MaskedText.visibleIconClass)
    }
  }
}

document.addEventListener("DOMContentLoaded", () => {
  for (const element of document.querySelectorAll(DATA_SELECTOR)) {
    new MaskedText(element)
  }
})
