import PropTypes from "prop-types"

import PopoverButton from "~/components/PopoverButton"
import Form from "~/design/forms"
import { globalsStateWrapper } from "~/redux"
import types from "~/types"

function EncounterMeetingTypeField({ meetingTypes, enabled, errorKey, name }) {
  if (enabled) {
    return (
      <Form.Group name={name} errorKey={errorKey}>
        <Form.Label>
          Was this a care conference or interdisciplinary team meeting?{" "}
          <PopoverButton
            title="Was this a care conference or interdisciplinary team meeting?"
            label="?"
            placement="left"
          >
            <ul>
              <li>A Care Conference is an informal or routine communication with care team member(s).</li>
              <li>
                Interdisciplinary Team Meetings are formal meetings for members where both the member and/or caregiver
                are present along with care team member(s) to ensure member needs are addressed in a comprehensive
                manner.
              </li>
            </ul>
          </PopoverButton>
        </Form.Label>
        <Form.SelectBox options={meetingTypes} isClearable />
      </Form.Group>
    )
  }

  return null
}

EncounterMeetingTypeField.propTypes = {
  meetingTypes: PropTypes.arrayOf(types.selectOption).isRequired,
  enabled: PropTypes.string.isRequired,
  errorKey: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default globalsStateWrapper(({ meetingTypes }) => ({ meetingTypes }))(EncounterMeetingTypeField)
