import { useEffect, useState } from "react"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import { isSelectQuestionType } from "../../responses/helpers/questionHelpers"

import { BulletDivider } from "~/components/Divider"
import { LinkButton } from "~/design/buttons"
import Show from "~/design/Show"

function CareSuggestionLinkingButton({ isCareSuggestionLinkingSurfaced, onClick, setIsCareSuggestionLinkingSurfaced }) {
  const { values: question, setFieldValue } = useFormikContext()
  const { questionTypeId, optionsAttributes } = question

  const [disabled, setDisabled] = useState(false)

  // hides linking UI if question type is not a select-question type
  useEffect(() => {
    if (!isSelectQuestionType(questionTypeId)) {
      setIsCareSuggestionLinkingSurfaced(false)
    }
  }, [questionTypeId, setIsCareSuggestionLinkingSurfaced])

  // enables button to display linking UI if there are existing options (not including
  // ones marked for destruction); disables button and hides UI if there are no options
  useEffect(() => {
    if (optionsAttributes.filter((option) => !("_destroy" in option)).length > 0) {
      setDisabled(false)
    } else {
      setIsCareSuggestionLinkingSurfaced(false)
      setDisabled(true)
    }
  }, [optionsAttributes, setIsCareSuggestionLinkingSurfaced, setFieldValue])

  // clears out linked suggestions for all options if linking UI is hidden
  useEffect(() => {
    if (!isCareSuggestionLinkingSurfaced) {
      optionsAttributes.forEach((opt, optIndex) => {
        setFieldValue(`optionsAttributes[${optIndex}].careSuggestionIds`, [])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCareSuggestionLinkingSurfaced, setFieldValue])

  return (
    <Show when={isSelectQuestionType(questionTypeId)}>
      <LinkButton onClick={onClick} disabled={disabled}>
        {isCareSuggestionLinkingSurfaced ? "Clear Care Plan Suggestions" : "Link Care Plan Suggestions"}
      </LinkButton>{" "}
      <BulletDivider />{" "}
    </Show>
  )
}

CareSuggestionLinkingButton.propTypes = {
  isCareSuggestionLinkingSurfaced: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  setIsCareSuggestionLinkingSurfaced: PropTypes.func.isRequired,
}

export default CareSuggestionLinkingButton
