import { Button, Col, Row } from "react-bootstrap"
import classNames from "classnames"
import { FieldArray } from "formik"
import PropTypes from "prop-types"

import PopoverButton from "~/components/PopoverButton"
import { IconButton, SecondaryButton } from "~/design/buttons"
import Form from "~/design/forms"
import { DeleteIcon, PlusIcon } from "~/design/icons"
import { harp_hcbs_eligibility_assessment_document_path } from "~/routes"
import types from "~/types"

function FormFields({ hasActiveClaim, hcbsEligibilityAssessmentDocumentTypes, hcbsEligibilityTypes }) {
  function downloadPath(assessmentId, document) {
    return harp_hcbs_eligibility_assessment_document_path(assessmentId, document.id, document.file.name)
  }

  return (
    <>
      <Form.Fieldset title="Assessment Details">
        <Form.Group name="date">
          <Form.Label>Assessment Date</Form.Label>
          <Form.DatePicker disabled={hasActiveClaim} />
        </Form.Group>
        <Form.Group name="resultId">
          <Form.Label>Assessment Result</Form.Label>
          <Form.SelectBox options={hcbsEligibilityTypes} isClearable />
        </Form.Group>
      </Form.Fieldset>
      <Form.Fieldset title="Documents">
        <FieldArray name="documents">
          {({ push, remove, form }) => (
            <>
              {form.values.documents.map((document, index) => (
                <Row key={index} className={classNames("mb-3 pt-3", { "bg-light": document._destroy })}>
                  <Col lg={3}>
                    {document.id ? (
                      <div className="d-flex justify-content-end p-1">
                        <Button
                          variant="link"
                          size="sm"
                          data-trigger-download-modal-sync={downloadPath(form.values.id, document)}
                        >
                          Download Document
                        </Button>
                      </div>
                    ) : (
                      <Form.Group clearOnHide={false} name={`documents[${index}].file`}>
                        <Form.Label width={3}>File</Form.Label>
                        <Form.FileField width={9} disabled={document._destroy} />
                      </Form.Group>
                    )}
                  </Col>
                  <Col lg={5}>
                    <Form.Group
                      clearOnHide={false}
                      name={`documents[${index}].kindId`}
                      errorKey={`documents[${index}].kind`}
                    >
                      <Form.Label width={3}>
                        Type{" "}
                        <PopoverButton label="?" title="Eligibility Assessment File Types">
                          <p>
                            <strong>Eligibility Assessment Report:</strong>
                            Select this if you are attaching the assessment itself.
                          </p>
                          <p>
                            <strong>Eligibility Summary Report:</strong>
                            This report summarizes the assessment information and outcome.
                          </p>
                          <p>
                            <strong>Combined Assessment Report and Summary Report:</strong>
                            Select this if you are attaching one file with both reports.
                          </p>
                        </PopoverButton>
                      </Form.Label>
                      <Form.SelectBox
                        width={9}
                        options={hcbsEligibilityAssessmentDocumentTypes}
                        isClearable
                        disabled={document._destroy}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    {document.id ? (
                      <Form.Group name={`documents[${index}]._destroy`}>
                        <Form.MarkForDelete offset={0} />
                      </Form.Group>
                    ) : (
                      <div className="mb-3 p-1">
                        <IconButton Icon={DeleteIcon} label="Remove barrier row" onClick={() => remove(index)} />
                      </div>
                    )}
                  </Col>
                </Row>
              ))}
              <div className="p-3">
                <SecondaryButton onClick={() => push({ id: null, file: null, kindId: null })}>
                  <PlusIcon />
                  Add Document
                </SecondaryButton>
              </div>
            </>
          )}
        </FieldArray>
      </Form.Fieldset>
    </>
  )
}

FormFields.propTypes = {
  hasActiveClaim: PropTypes.bool,
  hcbsEligibilityAssessmentDocumentTypes: PropTypes.arrayOf(types.selectOption).isRequired,
  hcbsEligibilityTypes: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default FormFields
