import PropTypes from "prop-types"

const careSuggestion = PropTypes.shape({
  id: PropTypes.number,
  text: PropTypes.string,
  active: PropTypes.bool,
  healthHomeId: PropTypes.number,
  createdAt: PropTypes.string,
})

export default careSuggestion
