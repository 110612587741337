import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import Form from "~/design/forms"
import Show from "~/design/Show"
import types from "~/types"

function OtherDetailsSection({ relationships }) {
  const {
    values: { relationshipId },
  } = useFormikContext()

  function showOtherDetails() {
    if (!relationshipId) {
      return false
    }

    return !!relationships.flatMap((e) => e.options).find((el) => el.value === relationshipId).other
  }

  return (
    <Show when={showOtherDetails()}>
      <Form.Group name="otherRelationshipDetails">
        <Form.Label>Other Details</Form.Label>
        <Form.TextField />
      </Form.Group>
    </Show>
  )
}

OtherDetailsSection.propTypes = {
  relationships: PropTypes.arrayOf(types.selectGroupedOptions).isRequired,
}

export default OtherDetailsSection
