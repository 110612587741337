import Form from "~/design/forms"

function EncounterHealthHomeServingChildrenTelehealthField() {
  return (
    <Form.Group name="healthHomeServingChildrenTelehealth" errorKey="healthHomeServingChildrenTelehealthId">
      <Form.CheckBox label="Telehealth encounter at the request of the member or caregiver in lieu of in-person contact" />
    </Form.Group>
  )
}

export default EncounterHealthHomeServingChildrenTelehealthField
