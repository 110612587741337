import { Button } from "react-bootstrap"
import classnames from "classnames"
import PropTypes from "prop-types"

/**
 * An `<IconButton />` is used for space-limited situations where you need a
 * clickable icon with no visible accompanying text.
 */
function IconButton({ disabled, Icon, label, onClick, size, color }) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      size={size}
      type="button"
      variant="link"
      className={classnames("icon-button", color)}
    >
      <span className="sr-only">{label}</span>
      <Icon />
    </Button>
  )
}

IconButton.propTypes = {
  /**
   * The Icon component to use. This should be an icon component from our set of design components.
   */
  Icon: PropTypes.elementType.isRequired,
  /**
   * Text to describe the action performed when clicking the button. This text is visually hidden,
   * but will be announced to screen readers.
   */
  label: PropTypes.string.isRequired,
  /**
   * A disabled button receives no pointer events.
   */
  disabled: PropTypes.bool,
  /**
   * The action to take when the button is clicked.
   */
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["sm", "lg"]),
  color: PropTypes.oneOf([
    "text-primary",
    "text-secondary",
    "text-success",
    "text-danger",
    "text-warning",
    "text-info",
  ]),
}

export default IconButton
