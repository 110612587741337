import { Col, Row } from "react-bootstrap"
import { useQuery } from "@tanstack/react-query"
import { FieldArray, useFormikContext } from "formik"
import PropTypes from "prop-types"

import { IconButton, LinkButton } from "~/design/buttons"
import Form from "~/design/forms"
import { CloseIcon } from "~/design/icons"
import Show from "~/design/Show"
import FeatureFlag from "~/featureFlags/FeatureFlag"
import { useIsFeatureEnabled } from "~/featureFlags/useFeatureFlagsContext"
import api from "~/services/api"

function SchemaQuestionCardOptionsSection({ questionIndex, healthHomeId, isCareSuggestionLinkingSurfaced }) {
  const { values: question, setFieldValue } = useFormikContext()

  // TODO: FCM-7736, can remove ternary conditions once feature flag is removed.
  // The failure branch prevents us from repeatedly hitting the feature-flagged
  // controller, which triggers a RoutingError and surfaces a 404 error in the
  // console everytime this AJAX query is re-run.
  const { isLoading, data: careSuggestions } = useQuery({
    queryKey: ["careSuggestionId", healthHomeId],
    queryFn: useIsFeatureEnabled("carePlanSuggestions")
      ? () => api.careSuggestions.get(healthHomeId)
      : () => {
          return []
        },
    initialData: [],
  })

  return (
    <FieldArray
      name="optionsAttributes"
      render={({ push, remove }) => (
        <>
          <Row className="mb-3">
            <Col md={7}>Answer Options</Col>
            <FeatureFlag flag="carePlanSuggestions">
              <Show when={isCareSuggestionLinkingSurfaced}>
                <Col md={5}>Care Suggestions</Col>
              </Show>
            </FeatureFlag>
          </Row>
          <ol>
            {question.optionsAttributes.map((option, optionIndex) => {
              if (option._destroy) {
                return null
              }

              function removeQuestion() {
                if (option.id) {
                  setFieldValue(`optionsAttributes[${optionIndex}]._destroy`, true)
                } else {
                  remove(optionIndex)
                }
              }

              return (
                <li key={optionIndex}>
                  <Row>
                    <Col md={6}>
                      <Form.Group
                        clearOnHide={false}
                        name={`optionsAttributes[${optionIndex}].optionText`}
                        errorKey={`options[${optionIndex}].optionText`}
                      >
                        <Form.Label hidden>
                          Question {questionIndex + 1} Option {optionIndex + 1}
                        </Form.Label>
                        <Form.TextField placeholder="Option Text" />
                      </Form.Group>
                    </Col>

                    <Col md={1} className="pl-0">
                      <IconButton
                        Icon={CloseIcon}
                        label={`Remove Question ${questionIndex + 1} Option ${optionIndex + 1}`}
                        onClick={removeQuestion}
                      />
                    </Col>
                    <FeatureFlag flag="carePlanSuggestions">
                      <Show when={isCareSuggestionLinkingSurfaced}>
                        <Col md={5} className="pl-0">
                          <Form.Group
                            clearOnHide={false}
                            name={`optionsAttributes[${optionIndex}].careSuggestionIds`}
                            errorKey={`options[${optionIndex}].careSuggestionIds`}
                          >
                            <Form.Label hidden>
                              Question {questionIndex + 1} Option {optionIndex + 1} Care Suggestions
                            </Form.Label>
                            <Form.MultiSelectBox
                              options={careSuggestions.map((option) => ({
                                label: option.text,
                                value: option.id,
                              }))}
                              isClearable
                              isLoading={isLoading}
                            />
                          </Form.Group>
                        </Col>
                      </Show>
                    </FeatureFlag>
                  </Row>
                </li>
              )
            })}
            <li>
              <LinkButton onClick={() => push({ optionText: "", careSuggestionIds: [] })}>Add Option</LinkButton>
            </li>
          </ol>
        </>
      )}
    />
  )
}

SchemaQuestionCardOptionsSection.propTypes = {
  questionIndex: PropTypes.number.isRequired,
  healthHomeId: PropTypes.number.isRequired,
  isCareSuggestionLinkingSurfaced: PropTypes.bool.isRequired,
}

export default SchemaQuestionCardOptionsSection
