import PropTypes from "prop-types"

import CareManagerSection from "./careTeam/CareManagerSection"
import CareTeamMembershipSection from "./careTeam/CareTeamMembershipSection"
import DiagnosisSection from "./diagnoses/DiagnosisSection"
import FamilyAndCommunityTeamMembershipSection from "./familyAndCommunityTeam/FamilyAndCommunityTeamMembershipSection"
import MedicationSection from "./medications/MedicationSection"

import types from "~/types"

function MedicalBackgroundWidget({
  chart,
  medicalBackground: {
    diagnoses,
    diagnosisValues,
    medications,
    careManager,
    careTeamMemberships,
    familyAndCommunityTeamMemberships,
    relationships,
    preferredContactMethods,
    providerCredentials,
    providerTitles,
    providerTypes,
    uniteUsEnabled,
  },
}) {
  return (
    <div className="border bg-light mb-3" data-testid="medical-background-widget">
      <DiagnosisSection chart={chart} diagnoses={diagnoses} diagnosisValues={diagnosisValues} />
      <MedicationSection chart={chart} medications={medications} />
      <CareManagerSection careManager={careManager} />
      <CareTeamMembershipSection
        careTeamMemberships={careTeamMemberships}
        chart={chart}
        providerCredentials={providerCredentials}
        providerTitles={providerTitles}
        providerTypes={providerTypes}
        uniteUsEnabled={uniteUsEnabled}
      />
      <FamilyAndCommunityTeamMembershipSection
        memberships={familyAndCommunityTeamMemberships}
        chart={chart}
        relationships={relationships}
        preferredContactMethods={preferredContactMethods}
      />
    </div>
  )
}

MedicalBackgroundWidget.propTypes = {
  chart: types.chart,
  medicalBackground: types.medicalBackground,
  uniteUsEnabled: PropTypes.bool.isRequired,
}

export default MedicalBackgroundWidget
