import PropTypes from "prop-types"

import FormFields from "./components/FormFields"

import { redirectTo } from "~/browserHelpers"
import { DangerButton } from "~/design/buttons"
import Form from "~/design/forms"
import useConfirmationModal, { ConfirmationModalProvider } from "~/design/modals/useConfirmationModal"
import Show from "~/design/Show"
import { chart_harp_workflow_status_path } from "~/routes"
import api from "~/services/api"
import { toast, ToastContainer } from "~/toast"
import types from "~/types"

function Edit({ chart, hcbsEligibilityAssessment, hcbsEligibilityAssessmentDocumentTypes, hcbsEligibilityTypes }) {
  const confirm = useConfirmationModal()

  async function handleSubmit(values, { setStatus, setErrors }) {
    // Rename `documents` to `documentsAttributes` for nested attributes
    const { documents, ...assessment } = values

    // Remove file attribute for already-uploaded documents
    assessment.documentsAttributes = documents.map(({ file, ...document }) => {
      if (document.id) {
        return document
      } else {
        return { ...document, file }
      }
    })

    try {
      await api.harp.hcbsEligibilityAssessments.patch(hcbsEligibilityAssessment.id, assessment)
      setStatus("success")
      redirectTo(chart_harp_workflow_status_path(chart.id))
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  async function handleDelete() {
    const confirmed = await confirm.danger("Are you sure you want to delete this HCBS Eligibility Assessment?", {
      title: "Delete HCBS Eligibility Assessment",
      confirmButtonLabel: "Delete",
    })

    if (confirmed) {
      try {
        await api.harp.hcbsEligibilityAssessments.delete(hcbsEligibilityAssessment.id)
        redirectTo(chart_harp_workflow_status_path(chart.id))
      } catch (e) {
        toast.error(`Could not delete this assessment: ${e.message}`)
      }
    }
  }

  return (
    <Form initialValues={hcbsEligibilityAssessment} onSubmit={handleSubmit}>
      <FormFields
        hasActiveClaim={hcbsEligibilityAssessment.hasActiveClaim}
        hcbsEligibilityAssessmentDocumentTypes={hcbsEligibilityAssessmentDocumentTypes}
        hcbsEligibilityTypes={hcbsEligibilityTypes}
      />
      <Form.ActionBar
        left={
          <Show when={!hcbsEligibilityAssessment.hasActiveClaim}>
            <DangerButton onClick={handleDelete}>Delete Assessment</DangerButton>
          </Show>
        }
        right={
          <>
            <Form.CancelButton onClick={() => redirectTo(chart_harp_workflow_status_path(chart.id))} />
            <Form.SubmitButton label={"Update Assessment"} />
          </>
        }
      />
    </Form>
  )
}

Edit.propTypes = {
  chart: types.chart.isRequired,
  hcbsEligibilityAssessment: types.harpHcbsEligibilityAssessment.isRequired,
  hcbsEligibilityAssessmentDocumentTypes: PropTypes.arrayOf(types.selectOption).isRequired,
  hcbsEligibilityTypes: PropTypes.arrayOf(types.selectOption).isRequired,
}

function EditProvider(props) {
  return (
    <ConfirmationModalProvider>
      <Edit {...props} />
      <ToastContainer />
    </ConfirmationModalProvider>
  )
}

export default EditProvider
