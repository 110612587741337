import { Col } from "react-bootstrap"
import { FieldArray, useFormikContext } from "formik"
import PropTypes from "prop-types"

import { IconButton, LinkButton } from "~/design/buttons"
import Form from "~/design/forms"
import { CloseIcon } from "~/design/icons"
import Show from "~/design/Show"
import types from "~/types"

function NeedRecipientFormFields({ needIndex, familyAndCommunityTeamMemberOptions }) {
  const {
    values: { needs },
    setFieldValue,
  } = useFormikContext()

  return (
    <FieldArray
      name={`needs[${needIndex}].needRecipients`}
      render={({ push, remove }) => (
        <>
          {needs[needIndex].needRecipients.map((needRecipient, recipientIndex) => {
            const findRecipientFromSelectOptions = familyAndCommunityTeamMemberOptions.find(
              (opt) => opt.value === needRecipient.familyAndCommunityTeamMembershipId
            )

            const isPersisted = Boolean(needRecipient.id)

            const getAvailableOptions = (needIndex, recipientIndex) => {
              const selectedOptions = needs[needIndex].needRecipients
                .filter((item, i) => item._destroy !== true && i !== recipientIndex)
                .map((selected) => selected.familyAndCommunityTeamMembershipId)

              return familyAndCommunityTeamMemberOptions.filter((option) => !selectedOptions.includes(option.value))
            }

            if (needRecipient._destroy) {
              return null
            }

            function removeRecipient() {
              if (needRecipient.id) {
                setFieldValue(`needs[${needIndex}].needRecipients[${recipientIndex}]._destroy`, true)
              } else {
                remove(recipientIndex)
              }
            }

            return (
              <div
                key={`${needIndex}-${recipientIndex}`}
                className="form-row align-items-center"
                data-testid={`hfw-need-recipient-${needIndex}-${recipientIndex}`}
              >
                <Col>
                  <Show when={!isPersisted || !!findRecipientFromSelectOptions}>
                    <Form.Group
                      clearOnHide={false}
                      name={`needs[${needIndex}].needRecipients[${recipientIndex}].familyAndCommunityTeamMembershipId`}
                    >
                      <Form.SelectBox
                        placeholder="Other Need Recipient"
                        options={getAvailableOptions(needIndex, recipientIndex)}
                      />
                    </Form.Group>
                  </Show>
                  <Show when={isPersisted && !findRecipientFromSelectOptions}>
                    <div className="border rounded align-middle p-2 pl-3 mb-3 text-muted">
                      <span>{`${needRecipient.firstName} ${needRecipient.lastName}`}</span>
                    </div>
                  </Show>
                </Col>
                <Col className="col-auto">
                  <div className="mb-3">
                    <IconButton Icon={CloseIcon} onClick={removeRecipient} label={"Remove Need recipient"} />
                  </div>
                </Col>
              </div>
            )
          })}
          <div className="d-flex">
            <LinkButton onClick={() => push({ familyAndCommunityTeamMembershipId: null })}>Add Recipient</LinkButton>
          </div>
        </>
      )}
    />
  )
}

NeedRecipientFormFields.propTypes = {
  needIndex: PropTypes.number.isRequired,
  familyAndCommunityTeamMemberOptions: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default NeedRecipientFormFields
