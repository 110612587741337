import Select from "react-select"
import { useField } from "formik"
import PropTypes from "prop-types"

import { DEFAULT_CONTROL_WIDTH } from "./constants"
import FieldErrors from "./FieldErrors"
import HelpText from "./HelpText"
import { classNames } from "./selectBoxConfiguration"
import useDisableContext from "./useDisableContext"
import useErrorContext from "./useErrorContext"
import { LayoutWrapper } from "./useLayoutContext"
import useNameContext from "./useNameContext"

/**
 * A `<MultiSelectBox />` represents a dropdown control from which a user
 * can select an option from a pre-determined list.
 */
function MultiSelectBox({
  disabled: disabledByProps,
  helpText,
  isClearable,
  isLoading,
  options,
  placeholder,
  validate,
  width,
}) {
  const { id, name } = useNameContext()
  const [field, , helpers] = useField({ name, validate })
  const { hasError } = useErrorContext()
  const disabledByContext = useDisableContext()

  const helpTextId = `${id}HelpText`
  const errorMessageId = `${id}Errors`
  const disabled = disabledByContext || disabledByProps
  const selectedOptions = field.value ? options?.filter((opt) => field.value.includes(opt.value)) : []

  function noOptionsMessage({ inputValue }) {
    if (!inputValue) {
      return "Oops! There are no options here."
    }
    return `Oops! There are no options that contain "${inputValue}".`
  }

  function handleChange(selectedOptions) {
    const value = selectedOptions?.map((opt) => opt.value) || null
    helpers.setValue(value)
  }

  return (
    <LayoutWrapper sm={width}>
      <Select
        {...field}
        aria-describedby={helpText ? helpTextId : null}
        aria-errormessage={errorMessageId}
        aria-invalid={hasError}
        aria-label={`${name} select box`}
        aria-labelledby={`${id}Label`}
        className="react-select"
        classNamePrefix="react-select"
        classNames={classNames}
        id={`${id}MultiSelectBox`}
        inputId={id}
        isClearable={isClearable}
        isDisabled={disabled}
        isMulti
        name={name}
        noOptionsMessage={noOptionsMessage}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        value={selectedOptions}
        isLoading={isLoading}
      />
      <HelpText id={helpTextId}>{helpText}</HelpText>
      <FieldErrors id={errorMessageId} />
    </LayoutWrapper>
  )
}

MultiSelectBox.propTypes = {
  /**
   * Whether the Multiselectbox can receive input.
   */
  disabled: PropTypes.bool,
  /**
   * Text intended to guide the user toward the correct input.
   */
  helpText: PropTypes.string,
  /**
   * When true, adds a button to clear the current value of the field.
   */
  isClearable: PropTypes.bool,
  /**
   * When true, renders a '...' and message to indicate that options are loading.
   */
  isLoading: PropTypes.bool,
  /**
   * The options to display within the select box.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  /**
   * The text to display before an option has been chosen.
   */
  placeholder: PropTypes.string,
  /**
   * An optional function to run client-side validations:
   * https://formik.org/docs/api/field#validate
   */
  validate: PropTypes.func,
  /**
   * The number of columns the control should span.
   */
  width: PropTypes.number,
}

MultiSelectBox.defaultProps = {
  disabled: false,
  isClearable: false,
  isLoading: false,
  placeholder: "Please select a value...",
  width: DEFAULT_CONTROL_WIDTH,
}

export default MultiSelectBox
