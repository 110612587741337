import PropTypes from "prop-types"

const customAssessmentSchemaQuestion = PropTypes.shape({
  id: PropTypes.number,
  questionText: PropTypes.string.isRequired,
  questionTypeId: PropTypes.number.isRequired,
  isRequired: PropTypes.bool.isRequired,
  position: PropTypes.number,
  dependsOnQuestionId: PropTypes.number,
  optionsAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      optionText: PropTypes.string.isRequired,
      careSuggestionIds: PropTypes.arrayOf(PropTypes.number),
    })
  ).isRequired,
  dependsOnQuestionOptionIds: PropTypes.arrayOf(PropTypes.number),
  sectionId: PropTypes.number.isRequired,
})

export default customAssessmentSchemaQuestion
