import { useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import PropTypes from "prop-types"

import FormFields from "./FormFields"
import SideBar from "./SideBar"

import { redirectTo } from "~/browserHelpers"
import Form from "~/design/forms"
import { high_fidelity_wrap_child_and_family_team_meeting_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"

function EditForm({
  educationEmploymentStatuses,
  childAndFamilyTeamMeeting,
  saveButtonDescription,
  completeButtonDescription,
  familyAndCommunityTeamMemberOptions,
  memberGroupedSelectOptions,
}) {
  const [complete, setComplete] = useState(false)

  async function handleSubmit(values, { setErrors, setStatus }) {
    const { ...childAndFamilyTeamMeeting } = values

    const childAndFamilyTeamMeetingParams = {
      ...childAndFamilyTeamMeeting,
    }

    try {
      await api.highFidelityWrap.childAndFamilyTeamMeetings.patch(
        childAndFamilyTeamMeeting.id,
        childAndFamilyTeamMeetingParams,
        complete
      )
      setStatus("success")
      redirectTo(high_fidelity_wrap_child_and_family_team_meeting_path(childAndFamilyTeamMeeting.id))
    } catch ({ errors }) {
      setErrors(errors)
      window.scrollTo(0, 0)
    }
  }

  return (
    <Form vertical initialValues={childAndFamilyTeamMeeting} onSubmit={handleSubmit}>
      <Row>
        <Col md={3}>
          <SideBar />
        </Col>
        <Col md={9}>
          <FormFields
            educationEmploymentStatuses={educationEmploymentStatuses}
            saveButtonDescription={saveButtonDescription}
            completeButtonDescription={completeButtonDescription}
            familyAndCommunityTeamMemberOptions={familyAndCommunityTeamMemberOptions}
            memberGroupedSelectOptions={memberGroupedSelectOptions}
          />

          <Form.ActionBar
            right={
              <>
                <Form.CancelButton
                  onClick={() =>
                    redirectTo(high_fidelity_wrap_child_and_family_team_meeting_path(childAndFamilyTeamMeeting.id))
                  }
                />
                <Button type="submit" variant="outline-primary">
                  Save
                </Button>
                <Button type="submit" variant="primary" onClick={() => setComplete(true)}>
                  Complete
                </Button>
              </>
            }
          />
        </Col>
      </Row>
    </Form>
  )
}

EditForm.propTypes = {
  childAndFamilyTeamMeeting: types.highFidelityWrapChildAndFamilyTeamMeeting.isRequired,
  educationEmploymentStatuses: PropTypes.arrayOf(types.selectOption).isRequired,
  saveButtonDescription: PropTypes.string.isRequired,
  completeButtonDescription: PropTypes.string.isRequired,
  familyAndCommunityTeamMemberOptions: PropTypes.arrayOf(types.selectOption).isRequired,
  memberGroupedSelectOptions: PropTypes.arrayOf(types.selectGroupedOptions).isRequired,
}

export default EditForm
