import { useState } from "react"
import { Col, Row } from "react-bootstrap"
import PropTypes from "prop-types"

import PreviewResponseForm from "./components/PreviewResponseForm"
import ReadOnlyResponseForm from "./components/ReadOnlyResponseForm"
import ResponseForm from "./components/ResponseForm"
import SideBar from "./components/SideBar"
import { getDefaultResponseValue } from "./helpers/questionHelpers"
import * as customAssessmentResponseTypes from "./types"

import { ConfirmationModalProvider } from "~/design/modals/useConfirmationModal"
import { FeatureFlagsProvider } from "~/featureFlags"
import { ToastContainer } from "~/toast"
import types from "~/types"

function Show({
  schemaVersion,
  response: initialResponse,
  isAdminOrAgencyAdmin,
  chart,
  medicalBackground,
  socialBackground,
  carePlanSuggestionsFeatureEnabled,
}) {
  const [response, setResponse] = useState(initialResponse)
  const isCompleted = Boolean(response.completedAt)
  const isPreview = !response.questionResponses
  const questionResponseValues = {}

  schemaVersion.sections.forEach((section) => {
    section.questions.forEach(({ questionResponseId, questionTypeId }) => {
      if (!isPreview && "value" in response.questionResponses[questionResponseId]) {
        questionResponseValues[questionResponseId] = response.questionResponses[questionResponseId].value
      } else {
        questionResponseValues[questionResponseId] = getDefaultResponseValue(questionTypeId)
      }
    })
  })

  let SelectedResponseForm
  if (isPreview) {
    SelectedResponseForm = (
      <PreviewResponseForm
        questionResponseValues={questionResponseValues}
        response={response}
        schemaVersion={schemaVersion}
      />
    )
  } else if (isCompleted) {
    SelectedResponseForm = (
      <ReadOnlyResponseForm
        questionResponseValues={questionResponseValues}
        response={response}
        schemaVersion={schemaVersion}
        isAdminOrAgencyAdmin={isAdminOrAgencyAdmin}
        setResponse={setResponse}
      />
    )
  } else {
    SelectedResponseForm = (
      <ResponseForm
        questionResponseValues={questionResponseValues}
        response={response}
        schemaVersion={schemaVersion}
        setResponse={setResponse}
      />
    )
  }

  return (
    <FeatureFlagsProvider userEnabledFeatures={{ carePlanSuggestions: carePlanSuggestionsFeatureEnabled }}>
      <ConfirmationModalProvider>
        <Row>
          <Col md={3}>
            <SideBar
              sections={schemaVersion.sections}
              medicalBackgroundWidgetEnabled={schemaVersion.medicalBackgroundWidgetEnabled}
              medicalBackground={medicalBackground}
              socialBackgroundWidgetEnabled={schemaVersion.socialBackgroundWidgetEnabled}
              socialBackground={socialBackground}
              chart={chart}
              isPreview={isPreview}
            />
          </Col>
          <Col md={9}>{SelectedResponseForm}</Col>
        </Row>
        <ToastContainer />
      </ConfirmationModalProvider>
    </FeatureFlagsProvider>
  )
}

Show.propTypes = {
  schemaVersion: customAssessmentResponseTypes.schemaVersion.isRequired,
  response: types.customAssessmentResponse,
  isAdminOrAgencyAdmin: PropTypes.bool.isRequired,
  chart: types.chart,
  medicalBackground: types.medicalBackground,
  socialBackground: types.socialBackground,
  carePlanSuggestionsFeatureEnabled: PropTypes.bool.isRequired,
}

Show.defaultProps = {
  isAdminOrAgencyAdmin: false,
}

export default Show
