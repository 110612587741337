import { IconButton } from "~/design/buttons"
import { CloneIcon } from "~/design/icons"
import types from "~/types"

function CareSuggestionNotification({ careSuggestionNotification }) {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(careSuggestionNotification.text)
  }

  return (
    <section key={careSuggestionNotification.id} className="rounded alert-primary text-primary p-2 my-2">
      <div className="d-inline px-2">{careSuggestionNotification.text}</div>
      <IconButton
        Icon={CloneIcon}
        label="Copy care plan suggestion"
        onClick={copyToClipboard}
        size="lg"
        color="text-primary"
      />
    </section>
  )
}

CareSuggestionNotification.propTypes = {
  careSuggestionNotification: types.careSuggestionNotification.isRequired,
}

export default CareSuggestionNotification
