import { submitAsyncRequest } from "~/asyncHelpers"
import {
  api_v1_chart_high_fidelity_wrap_child_and_family_team_meetings_path,
  api_v1_high_fidelity_wrap_child_and_family_team_meeting_path,
} from "~/routes"

const highFidelityWrap = {
  childAndFamilyTeamMeetings: {
    patch: function (id, childAndFamilyTeamMeeting, complete) {
      return submitAsyncRequest({
        url: api_v1_high_fidelity_wrap_child_and_family_team_meeting_path(id),
        type: "patch",
        data: { childAndFamilyTeamMeeting: childAndFamilyTeamMeeting, complete: complete },
        keyTransformOverrides: {
          _destroy: "_destroy",
          needs: "needs_attributes",
          needRecipients: "need_recipients_attributes",
          members: "members_attributes",
        },
      })
    },
    post: function (chartId, childAndFamilyTeamMeeting, complete) {
      return submitAsyncRequest({
        url: api_v1_chart_high_fidelity_wrap_child_and_family_team_meetings_path(chartId),
        type: "post",
        data: { childAndFamilyTeamMeeting: childAndFamilyTeamMeeting, complete: complete },
        keyTransformOverrides: {
          needs: "needs_attributes",
          needRecipients: "need_recipients_attributes",
          members: "members_attributes",
        },
      })
    },
  },
}

export default highFidelityWrap
