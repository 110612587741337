import { Table } from "react-bootstrap"
import classNames from "classnames"
import PropTypes from "prop-types"

import formattedDate from "~/dateHelpers"
import { LinkButton } from "~/design/buttons"
import Jumbotron from "~/design/Jumbotron"
import useConfirmationModal from "~/design/modals/useConfirmationModal"
import Show from "~/design/Show"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function MembershipsTable({ readOnly, memberships, onDelete, showEditModal }) {
  const confirm = useConfirmationModal()

  function membershipRelation(membership) {
    const relationshipName = membership.otherRelationshipDetails
      ? `${membership.relationshipName.replace("must specify", membership.otherRelationshipDetails)}`
      : `${membership.relationshipName}`
    return `${membership.relationshipCategory} - ${relationshipName}`
  }

  function memberContactInfo(member) {
    return [member.phone, member.address].filter(Boolean).join(" / ")
  }

  async function handleDelete(membership) {
    const confirmed = await confirm.danger(
      `Remove this member from the family and community team? If the membership is no longer active,
      please don't remove them — mark the membership 'Inactive' instead by clicking 'Edit.' If the membership was
      mistakenly added to family and community team, click 'Remove' to remove them.`,
      { confirmButtonLabel: "Remove" }
    )

    if (confirmed) {
      try {
        await api.familyAndCommunityTeamMemberships.delete(membership.id)
        toast.success("Family and Community team member removed.")
        onDelete(membership.id)
      } catch {
        toast.error(
          <p>
            Family and community member could not be removed.
            <br />
            Please refresh the page.
          </p>
        )
      }
    }
  }

  const sortedMemberships = [...memberships].sort((membershipA, membershipB) => membershipB.active - membershipA.active)

  if (memberships.length === 0) {
    return <Jumbotron message="This patient has no family and community team members for the selected agencies." />
  }

  return (
    <Table striped bordered size="sm" className="table-light-header">
      <thead>
        <tr>
          <th>Relationship</th>
          <th>Name</th>
          <th>Strength</th>
          <th>Contact Info</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Notes</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {sortedMemberships.map((membership) => (
          <tr
            key={membership.id}
            className={classNames({ "text-muted": !membership.active })}
            data-testid={`family-and-community-team-membership-row-${membership.id}`}
          >
            <td>
              {membershipRelation(membership)}
              {membership.active ? "" : " [INACTIVE]"}
            </td>
            <td>{membership.member.name}</td>
            <td>{membership.member.strength}</td>
            <td>{memberContactInfo(membership.member)}</td>
            <td>{formattedDate(membership.startDate)}</td>
            <td>{formattedDate(membership.endDate)}</td>
            <td>{membership.member.otherNotes}</td>
            <td width={120}>
              <Show when={!readOnly}>
                <LinkButton onClick={() => showEditModal(membership)}>Edit</LinkButton> |{" "}
                <LinkButton color="danger" onClick={() => handleDelete(membership)}>
                  Remove
                </LinkButton>
              </Show>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

MembershipsTable.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  memberships: PropTypes.arrayOf(types.familyAndCommunityTeamMembership).isRequired,
  onDelete: PropTypes.func.isRequired,
  showEditModal: PropTypes.func.isRequired,
}

export default MembershipsTable
