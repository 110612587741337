import PropTypes from "prop-types"

const careSuggestionNotification = PropTypes.shape({
  id: PropTypes.number,
  careSuggestionId: PropTypes.number,
  text: PropTypes.string,
  customAssessmentResponseId: PropTypes.number,
})

export default careSuggestionNotification
