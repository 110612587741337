import PropTypes from "prop-types"

const encounter = PropTypes.shape({
  id: PropTypes.number,
  date: PropTypes.string,
  coreServiceId: PropTypes.number,
  chartId: PropTypes.number.isRequired,
  carePlanTaskIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  clinicalEventIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  isAboutAdmissionOrDischarge: PropTypes.bool,
  healthHomeServingChildrenTelehealthId: PropTypes.number,
  mailEncounterDetailsAttributes: PropTypes.object,
  faxEncounterDetailsAttributes: PropTypes.object,
  textEncounterDetailsAttributes: PropTypes.object,
  emailEncounterDetailsAttributes: PropTypes.object,
  videoEncounterDetailsAttributes: PropTypes.object,
  inPersonEncounterDetailsAttributes: PropTypes.object,
  phoneEncounterDetailsAttributes: PropTypes.object,
  officeWorkEncounterDetailsAttributes: PropTypes.object,
  description: PropTypes.string,
  notes: PropTypes.string,
  locked: PropTypes.bool.isRequired,
})

export default encounter
