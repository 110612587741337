import { useState } from "react"
import PropTypes from "prop-types"

import MembershipsTable from "./components/MembershipsTable"

import { PrimaryButton } from "~/design/buttons"
import { ConfirmationModalProvider } from "~/design/modals/useConfirmationModal"
import Show from "~/design/Show"
import { ToastContainer } from "~/toast"
import types from "~/types"
import AddMembershipForm from "~/views/shared/familyAndCommunityTeam/AddMembershipForm"
import EditMembershipForm from "~/views/shared/familyAndCommunityTeam/EditMembershipForm"
import useMembershipModal from "~/views/shared/familyAndCommunityTeam/useMembershipModal"
import Section from "~/views/shared/patientDetails/Section"

function Index({ readOnly, memberships: initialMemberships, chart, relationships, preferredContactMethods }) {
  const [memberships, setMemberships] = useState(initialMemberships)

  const { selectedMembership, isAddModalVisible, isEditModalVisible, showAddModal, showEditModal, hideModal } =
    useMembershipModal()

  function handleAdd(membership) {
    setMemberships((careTeamMemberships) => [...careTeamMemberships, membership])
  }

  function handleDelete(membershipId) {
    setMemberships((memberships) => memberships.filter((membership) => membership.id !== membershipId))
  }

  function handleUpdate(updatedMembership) {
    setMemberships((memberships) =>
      memberships.map((membership) => (membership.id === updatedMembership.id ? updatedMembership : membership))
    )
  }

  return (
    <ConfirmationModalProvider>
      <Section
        title="Family and Community"
        actions={
          <>
            <Show when={!readOnly}>
              <PrimaryButton onClick={() => showAddModal()}>Add Family and Community Member</PrimaryButton>
            </Show>
          </>
        }
      >
        <MembershipsTable
          readOnly={readOnly}
          memberships={memberships}
          showEditModal={showEditModal}
          onDelete={handleDelete}
        />
        {chart && (
          <AddMembershipForm
            chart={chart}
            relationships={relationships}
            preferredContactMethods={preferredContactMethods}
            show={isAddModalVisible}
            onAdd={handleAdd}
            onClose={hideModal}
          />
        )}
        {selectedMembership ? (
          <EditMembershipForm
            membership={selectedMembership}
            show={isEditModalVisible}
            onClose={hideModal}
            onUpdate={handleUpdate}
            preferredContactMethods={preferredContactMethods}
            relationships={relationships}
          />
        ) : null}
        <ToastContainer />
      </Section>
    </ConfirmationModalProvider>
  )
}

Index.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  memberships: PropTypes.arrayOf(types.familyAndCommunityTeamMembership).isRequired,
  chart: types.chart,
  relationships: PropTypes.arrayOf(types.selectGroupedOptions).isRequired,
  preferredContactMethods: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default Index
