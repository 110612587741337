import PropTypes from "prop-types"

import FormFields from "./components/FormFields"

import { redirectTo } from "~/browserHelpers"
import Form from "~/design/forms"
import { chart_harp_workflow_status_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"

function New({ chart, hcbsEligibilityAssessment, hcbsEligibilityAssessmentDocumentTypes, hcbsEligibilityTypes }) {
  async function handleSubmit(values, { setStatus, setErrors }) {
    // Rename `documents` to `documentsAttributes` for nested attributes
    const { documents, ...assessment } = values
    assessment.documentsAttributes = documents

    try {
      await api.harp.hcbsEligibilityAssessments.post(chart.id, assessment)
      setStatus("success")
      redirectTo(chart_harp_workflow_status_path(chart.id))
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Form initialValues={hcbsEligibilityAssessment} onSubmit={handleSubmit}>
      <FormFields
        hcbsEligibilityAssessmentDocumentTypes={hcbsEligibilityAssessmentDocumentTypes}
        hcbsEligibilityTypes={hcbsEligibilityTypes}
      />
      <Form.ActionBar
        right={
          <>
            <Form.CancelButton onClick={() => redirectTo(chart_harp_workflow_status_path(chart.id))} />
            <Form.SubmitButton label={"Create Assessment"} />
          </>
        }
      />
    </Form>
  )
}

New.propTypes = {
  chart: types.chart.isRequired,
  hcbsEligibilityAssessment: types.harpHcbsEligibilityAssessment.isRequired,
  hcbsEligibilityAssessmentDocumentTypes: PropTypes.arrayOf(types.selectOption).isRequired,
  hcbsEligibilityTypes: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default New
