import { useState } from "react"
import PropTypes from "prop-types"

import AddCareSuggestionForm from "./AddCareSuggestionForm"
import CareSuggestionsBankTable from "./CareSuggestionsBankTable"

import { PrimaryButton } from "~/design/buttons"
import Jumbotron from "~/design/Jumbotron"
import { useModal } from "~/design/Modal"
import { ToastContainer } from "~/toast"
import types from "~/types"
import Section from "~/views/shared/patientDetails/Section"

function Index({ healthHome, careSuggestions: initialCareSuggestions }) {
  const [careSuggestions, setCareSuggestions] = useState(initialCareSuggestions)
  const [showModal, { open: openModal, close: closeModal }] = useModal()

  function handleAddCareSuggestion(newSuggestion) {
    setCareSuggestions([newSuggestion, ...careSuggestions])
  }

  return (
    <Section actions={<PrimaryButton onClick={openModal}>Add Care Suggestion</PrimaryButton>}>
      <AddCareSuggestionForm
        healthHome={healthHome}
        showModal={showModal}
        closeModal={closeModal}
        handleAddCareSuggestion={handleAddCareSuggestion}
      />
      {careSuggestions.length === 0 ? (
        <Jumbotron message="No care suggestions found" />
      ) : (
        <CareSuggestionsBankTable careSuggestions={careSuggestions} setCareSuggestions={setCareSuggestions} />
      )}

      <ToastContainer />
    </Section>
  )
}

Index.propTypes = {
  healthHome: types.healthHome.isRequired,
  careSuggestions: PropTypes.arrayOf(types.careSuggestion).isRequired,
}

export default Index
