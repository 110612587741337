import PropTypes from "prop-types"

import selectOption from "./selectOption"

const selectGroupedOptions = PropTypes.shape({
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(selectOption).isRequired,
})

export default selectGroupedOptions
