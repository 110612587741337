import { useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import { FieldArray, useFormikContext } from "formik"
import PropTypes from "prop-types"

import NeedRecipientFormFields from "./NeedRecipientFormFields"

import { IconButton, LinkButton } from "~/design/buttons"
import Form from "~/design/forms"
import { CloseIcon } from "~/design/icons"
import types from "~/types"

function NeedFormFields({ familyAndCommunityTeamMemberOptions }) {
  const rating = [1, 2, 3, 4, 5].map((number) => ({
    label: `${number}`,
    value: number,
  }))

  const YES_NO_OPTIONS = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ]

  const {
    values: { needs },
    setFieldValue,
  } = useFormikContext()

  const newNeedAttributes = {
    title: "",
    description: "",
    addressed: null,
    ratingOfMeetingNeed: null,
    context: "",
    forMember: false,
    forOther: false,
    needRecipients: [{ fullName: "" }],
  }

  useEffect(() => {
    needs.forEach((need, needIndex) => {
      if (!need.forOther) {
        need.needRecipients.forEach((recipient, recipientIndex) => {
          setFieldValue(`needs[${needIndex}].needRecipients[${recipientIndex}]._destroy`, true)
        })
      }
    })
  }, [needs, setFieldValue])

  return (
    <FieldArray
      name="needs"
      render={({ push, remove }) => (
        <>
          {needs.map((need, needIndex) => {
            if (need._destroy) {
              return null
            }

            function removeNeed() {
              if (need.id) {
                setFieldValue(`needs[${needIndex}]._destroy`, true)
              } else {
                remove(needIndex)
              }
            }

            return (
              <div key={needIndex} className="border rounded p-3 mb-3">
                <div className="d-flex justify-content-end">
                  <IconButton Icon={CloseIcon} onClick={removeNeed} label={"Remove Underlying Need"} />
                </div>
                <Form.Group clearOnHide={false} name={`needs[${needIndex}].title`}>
                  <Form.Label>Underlying Need #{needIndex + 1}</Form.Label>
                  <Form.TextArea />
                </Form.Group>

                <Form.Group clearOnHide={false} name={`needs[${needIndex}].description`}>
                  <Form.Label>Underlying Need Description</Form.Label>
                  <Form.TextArea />
                </Form.Group>

                <Row>
                  <Col md={2}>
                    <span>Underlying Need of:</span>
                  </Col>
                  <Col md={2}>
                    <Form.Group clearOnHide={false} name={`needs[${needIndex}].forMember`}>
                      <Form.CheckBox label="Member" />
                    </Form.Group>
                  </Col>
                  <Col md={8}>
                    <Form.Group clearOnHide={false} name={`needs[${needIndex}].forOther`}>
                      <Form.CheckBox label="Other" />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Show when={() => need.forOther === true}>
                  <div className="border rounded p-3 mb-3">
                    <NeedRecipientFormFields
                      needIndex={needIndex}
                      familyAndCommunityTeamMemberOptions={familyAndCommunityTeamMemberOptions}
                    />
                  </div>
                </Form.Show>

                <Form.Group clearOnHide={false} name={`needs[${needIndex}].addressed`}>
                  <Form.Label>Does the current care plan address the Underlying Need?</Form.Label>
                  <Form.SelectBox options={YES_NO_OPTIONS} />
                </Form.Group>

                <Form.Group clearOnHide={false} name={`needs[${needIndex}].ratingOfMeetingNeed`}>
                  <Form.Label>How close are we to having this underlying need met?</Form.Label>
                  <Form.SelectBox options={rating} />
                </Form.Group>

                <Form.Group clearOnHide={false} name={`needs[${needIndex}].context`}>
                  <Form.Label>Please provide more context on the progress made on this underlying need:</Form.Label>
                  <Form.TextArea />
                </Form.Group>
              </div>
            )
          })}
          <div className="d-flex justify-content-end">
            <LinkButton onClick={() => push(newNeedAttributes)}>Add Underlying Need</LinkButton>
          </div>
        </>
      )}
    />
  )
}

NeedFormFields.propTypes = {
  familyAndCommunityTeamMemberOptions: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default NeedFormFields
