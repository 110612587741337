import { Col } from "react-bootstrap"
import { FieldArray, useFormikContext } from "formik"
import PropTypes from "prop-types"

import { IconButton, LinkButton } from "~/design/buttons"
import Form from "~/design/forms"
import { CloseIcon } from "~/design/icons"
import Show from "~/design/Show"
import types from "~/types"

function MeetingMemberFormFields({ memberGroupedSelectOptions }) {
  const {
    values: { members },
    setFieldValue,
  } = useFormikContext()

  const handleSelectChange = (setFieldValue, fieldNamePrefix) => (selectedOption) => {
    if (selectedOption) {
      setFieldValue(`[${fieldNamePrefix}].cftmMemberId`, selectedOption.value)
      setFieldValue(`[${fieldNamePrefix}].cftmMemberType`, selectedOption.type)
    }
  }

  return (
    <FieldArray
      name="members"
      render={({ push, remove }) => (
        <>
          {members.map((member, memberIndex) => {
            const findMemberFromSelectOptions = memberGroupedSelectOptions
              .flatMap((group) => group.options)
              .find((opt) => opt.type === member.cftmMemberType && opt.value === member.cftmMemberId)

            const isPersisted = Boolean(member.id)

            if (member._destroy) {
              return null
            }

            function removeCareTeamMember() {
              if (member.id) {
                setFieldValue(`members[${memberIndex}]._destroy`, true)
              } else {
                remove(memberIndex)
              }
            }

            return (
              <div key={memberIndex} className="form-row align-items-center" data-testid="cftm-member">
                <Col>
                  <Show when={!isPersisted || !!findMemberFromSelectOptions}>
                    <Form.Group clearOnHide={false} name={`members.[${memberIndex}].cftmMemberId`}>
                      <Form.Label hidden="md">Care Team Member</Form.Label>
                      <Form.SelectBox
                        options={memberGroupedSelectOptions}
                        onChange={handleSelectChange(setFieldValue, `members.[${memberIndex}]`)}
                      />
                    </Form.Group>
                  </Show>
                  <Show when={isPersisted && !findMemberFromSelectOptions}>
                    <div className="border rounded align-middle p-2 pl-3 mb-3 text-muted">
                      <span>{`${member.firstName} ${member.lastName}, ${member.role}`}</span>
                    </div>
                  </Show>
                </Col>
                <Col className="col-auto">
                  <Form.Group clearOnHide={false} name={`members.[${memberIndex}].invited`}>
                    <Form.CheckBox label="Invited?" />
                  </Form.Group>
                </Col>
                <Col className="col-auto">
                  <Form.Group clearOnHide={false} name={`members.[${memberIndex}].attended`}>
                    <Form.CheckBox label="Attended?" />
                  </Form.Group>
                </Col>
                <Col className="col-auto">
                  <div className="mb-3">
                    <IconButton Icon={CloseIcon} onClick={removeCareTeamMember} label={"Remove Care Team Member"} />
                  </div>
                </Col>
              </div>
            )
          })}
          <div className="d-flex">
            <LinkButton onClick={() => push({ invited: false, attended: false })}>Add Care Team Member</LinkButton>
          </div>
        </>
      )}
    />
  )
}

MeetingMemberFormFields.propTypes = {
  memberGroupedSelectOptions: PropTypes.arrayOf(types.selectGroupedOptions).isRequired,
}

export default MeetingMemberFormFields
