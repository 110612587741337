import PropTypes from "prop-types"

import familyAndCommunityTeamMember from "./familyAndCommunityTeamMember"

const familyAndCommunityTeamMembership = PropTypes.shape({
  id: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  familyAndCommunityTeamMemberId: PropTypes.number.isRequired,
  relationshipId: PropTypes.number.isRequired,
  relationshipName: PropTypes.string.isRequired,
  relationshipCategory: PropTypes.string.isRequired,
  otherRelationshipDetails: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  member: familyAndCommunityTeamMember,
})

export default familyAndCommunityTeamMembership
