import { Col, Row, Table } from "react-bootstrap"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table"
import PropTypes from "prop-types"

import Pagination from "~/components/pagination"
import TablePageInfo from "~/components/TablePageInfo"
import { toAppDefaultDateTime } from "~/dateHelpers"
import { LinkButton } from "~/design/buttons"
import Show from "~/design/Show"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

const columnHelper = createColumnHelper()

function CareSuggestionsBankTable({ careSuggestions, setCareSuggestions }) {
  function assessmentAndVersionName(version) {
    return <li key={version.id}>{`${version.schemaName} - ${version.versionName}`}</li>
  }

  const columns = [
    columnHelper.accessor("createdAt", {
      header: "Date Added",
      id: "date_added",
      cell: ({ getValue }) => toAppDefaultDateTime(getValue()),
      colWidth: 2,
    }),
    columnHelper.accessor("text", {
      header: "Care Suggestion",
      id: "care_suggestion_text",
      colWidth: 4,
    }),
    columnHelper.accessor("linkedDraftAssessments", {
      header: "Linked Draft Assessments",
      id: "linked_draft_assessments",
      cell: ({ getValue }) => {
        return (
          <ul className="mb-0">
            {getValue().map((version) => {
              return assessmentAndVersionName(version)
            })}
          </ul>
        )
      },
      colWidth: 2,
    }),
    columnHelper.accessor("linkedActiveAssessments", {
      header: "Linked Active Assessments",
      id: "linked_active_assessments",
      cell: ({ getValue }) => {
        return (
          <ul className="mb-0">
            {getValue().map((version) => {
              return assessmentAndVersionName(version)
            })}
          </ul>
        )
      },
      colWidth: 2,
    }),
    columnHelper.accessor("archived", {
      header: "Status",
      id: "status",
      cell: ({ getValue }) => {
        return <>{getValue() ? "Archived" : "Active"}</>
      },
      colWidth: 1,
    }),
    columnHelper.display({
      header: "Action",
      id: "actions",
      cell: ({ row }) => {
        const careSuggestion = row.original

        return (
          <Show when={!careSuggestion.archived}>
            <LinkButton onClick={() => archiveSuggestion(careSuggestion)}>Archive</LinkButton>
          </Show>
        )
      },
      colWidth: 1,
    }),
  ]

  const table = useReactTable({
    data: careSuggestions,
    columns,
    initialState: {
      pagination: { pageSize: 30 },
    },
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  async function archiveSuggestion(careSuggestion) {
    try {
      const response = await api.careSuggestions.patch(careSuggestion.id)

      const updatedCareSuggestions = careSuggestions.map((careSuggestion) => {
        if (careSuggestion.id === response.id) {
          return response
        }
        return careSuggestion
      })

      setCareSuggestions(updatedCareSuggestions)

      toast.success("Care suggestion successfully archived.")
    } catch ({ errors }) {
      toast.error(
        <>
          <p>Care Suggestion could not be archived.</p>
          <>{errors.base}</>
        </>
      )
    }
  }

  const {
    pagination: { pageIndex, pageSize },
  } = table.getState()

  return (
    <>
      <Row>
        <Col md="8">
          <Pagination gotoPage={table.setPageIndex} pageCount={table.getPageCount()} pageIndex={pageIndex} />
        </Col>
        <Col md="4">
          <TablePageInfo
            className="float-right mt-2 mb-3"
            pageIndex={pageIndex}
            pageSize={pageSize}
            total={careSuggestions.length}
          />
        </Col>
      </Row>

      <Table striped bordered size="sm" className="table-light-header">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className={`col-${header.column.columnDef.colWidth}`}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} data-testid={`care-suggestion-${row.original.id}-row`}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      <Row>
        <Col md="8">
          <Pagination gotoPage={table.setPageIndex} pageCount={table.getPageCount()} pageIndex={pageIndex} />
        </Col>
        <Col md="4">
          <TablePageInfo pageIndex={pageIndex} pageSize={pageSize} total={careSuggestions.length} />
        </Col>
      </Row>
    </>
  )
}

CareSuggestionsBankTable.propTypes = {
  careSuggestions: PropTypes.arrayOf(types.careSuggestion).isRequired,
  setCareSuggestions: PropTypes.func.isRequired,
}

export default CareSuggestionsBankTable
