import PropTypes from "prop-types"

import Form from "~/design/forms"
import Modal from "~/design/Modal"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function AddCareSuggestionForm({ healthHome, showModal, closeModal, handleAddCareSuggestion }) {
  const initialValues = {
    text: "",
    healthHomeId: healthHome.id,
  }
  async function onSubmit(formData, { setErrors, setStatus }) {
    try {
      const careSuggestion = await api.careSuggestions.post(formData)
      handleAddCareSuggestion(careSuggestion)
      setStatus("success")
      toast.success("Care Suggestion successfully added.")
      closeModal()
    } catch ({ errors }) {
      setErrors(errors)
      toast.error("Care Suggestion was not saved.")
    }
  }

  return (
    <Modal show={showModal} onClose={closeModal} title="Add Care Suggestion" size="lg">
      <Form.Provider formId="add-care-suggestion-modal-form" initialValues={initialValues} onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Body vertical>
            <Form.Group name="text">
              <Form.Label>Care Suggestion</Form.Label>
              <Form.TextField placeholder="Enter a care suggestion" />
            </Form.Group>
          </Form.Body>
        </Modal.Body>
        <Modal.Footer>
          <Form.CancelButton onClick={closeModal} />
          <Form.SubmitButton label="Save" />
        </Modal.Footer>
      </Form.Provider>
    </Modal>
  )
}

AddCareSuggestionForm.propTypes = {
  healthHome: types.healthHome.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleAddCareSuggestion: PropTypes.func.isRequired,
}

export default AddCareSuggestionForm
