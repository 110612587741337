import { Col, Row } from "react-bootstrap"
import { parseISO } from "date-fns"
import PropTypes from "prop-types"

import EncounterFormTabs from "./EncounterFormTabs"
import EncounterHealthHomeServingChildrenTelehealthField from "./EncounterHealthHomeServingChildrenTelehealthField"
import EncounterModeFields, { ENCOUNTER_MODE_OPTIONS, encounterModeIdForEncounter } from "./EncounterModeFields"
import LegacyEncounterErrorMessage from "./LegacyEncounterErrorMessage"

import { redirectTo } from "~/browserHelpers"
import { LineDivider } from "~/components/Divider"
import PopoverButton from "~/components/PopoverButton"
import SuccessAlert from "~/design/alerts/SuccessAlert"
import Form from "~/design/forms"
import { globalsStateWrapper } from "~/redux"
import { chart_encounters_path, encounter_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"
import ChartIndicator from "~/views/shared/ChartIndicator"
import ChartSelector from "~/views/shared/ChartSelector"

function segmentActiveAsOfDate(date, segments) {
  if (date === null) {
    return false
  }

  for (const segment of segments) {
    if (
      parseISO(segment.startDate) <= parseISO(date) &&
      (segment.endDate === null || parseISO(segment.endDate) >= parseISO(date))
    ) {
      return true
    }
  }
  return false
}

const EncounterForm = ({
  carePlan,
  chart,
  charts,
  isAdmin,
  encounter,
  coreServiceTypes,
  clinicalEvents,
  autoLockingDelayText,
  healthHomeServingChildrenSegments,
}) => {
  const initialValues = Object.assign({}, encounter, {
    modeId: encounterModeIdForEncounter(encounter),
    // HealthHomeServingChildrenTelehealth::YES.value
    healthHomeServingChildrenTelehealth: encounter.healthHomeServingChildrenTelehealthId === 1,
  })
  const isPersisted = Boolean(encounter.id)

  function shouldShowHealthHomeServingChildrenTelehealthField(date, modeId) {
    // Video (id = 5) and Phone (id = 7) ENCOUNTER_MODE_OPTIONS
    return segmentActiveAsOfDate(date, healthHomeServingChildrenSegments) && [5, 7].includes(modeId)
  }

  const onSubmit = async (values, { setErrors, setStatus }) => {
    try {
      const params = {
        ...values,
        // HealthHomeServingChildrenTelehealth::YES.value
        healthHomeServingChildrenTelehealthId: values.healthHomeServingChildrenTelehealth ? 1 : null,
      }
      await (isPersisted ? api.encounters.patch(encounter.id, params) : api.encounters.post(encounter.chartId, params))

      if (isPersisted) {
        redirectTo(encounter_path(encounter.id))
      } else {
        redirectTo(chart_encounters_path(encounter.chartId))
      }

      setStatus("success")
    } catch ({ errors }) {
      if (errors.base) {
        errors.base = errors.base.map((error) => {
          if (
            error ===
            "This encounter does not qualify as a core service. See this article on our Support page for an explanation of core service rules."
          ) {
            return (
              <>
                This encounter does not qualify as a core service. See{" "}
                <a
                  href="https://fcm1.zendesk.com/hc/en-us/articles/30859014934164-Encounter-Types-and-Core-Service"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  this article <i className="fa fa-external-link" />
                </a>{" "}
                on our Support page for an explanation of core service rules.
              </>
            )
          }
          return error
        })
      }
      setErrors(errors)
    }
  }

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} vertical>
      {autoLockingDelayText && (
        <SuccessAlert>
          <p>
            This encounter will automatically lock {autoLockingDelayText} after this action is performed as per Health
            Home configuration.
          </p>
        </SuccessAlert>
      )}
      <div className="encounter-form">
        <Row>
          <Col lg={4}>
            <Form.Group name="date">
              <Form.Label>Encounter Date</Form.Label>
              <Form.DatePicker maxDate={new Date()} />
            </Form.Group>
            <Form.Group name="coreServiceId">
              <Form.Label>
                Core Service{" "}
                <PopoverButton title="About Core Services" label="?">
                  <div>
                    More information about core services is available from NYSDOH at{" "}
                    <a
                      href="http://www.health.ny.gov/health_care/medicaid/program/medicaid_health_homes/provider_qualification_standards.htm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      this link
                    </a>
                    .
                  </div>
                </PopoverButton>
              </Form.Label>
              <Form.SelectBox options={coreServiceTypes} isClearable />
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group name="modeId" errorKey="detailsType">
              <Form.Label>Mode</Form.Label>
              <Form.SelectBox options={ENCOUNTER_MODE_OPTIONS} isClearable />
              <LegacyEncounterErrorMessage />
            </Form.Group>
            <EncounterModeFields healthHomeServingChildrenSegments={healthHomeServingChildrenSegments} />
            <Form.Show
              when={({ values: { date, modeId } }) => shouldShowHealthHomeServingChildrenTelehealthField(date, modeId)}
            >
              <EncounterHealthHomeServingChildrenTelehealthField />
            </Form.Show>
          </Col>
          <Col lg={4}>
            <Form.Group name="isAboutAdmissionOrDischarge">
              <Form.Label>
                Is this in response to an admission or discharge?{" "}
                <PopoverButton title="Admission or discharge?" label="?" placement="left">
                  <div>
                    Is this in response to an admission or discharge? (Inpatient hospitalization, ED, rehab, detox,
                    skilled nurse facility, or incarceration)
                  </div>
                </PopoverButton>
              </Form.Label>
              <Form.SelectBox
                options={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
                isClearable
              />
            </Form.Group>
          </Col>
        </Row>
        <LineDivider />
        <Row>
          <Col lg={6}>
            <Form.Group name="notes">
              <Form.Label>Notes</Form.Label>
              <Form.TextArea rows={20} />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <EncounterFormTabs carePlan={carePlan} clinicalEvents={clinicalEvents} />
          </Col>
        </Row>

        <LineDivider />
        {isPersisted && isAdmin ? <ChartSelector charts={charts} /> : <ChartIndicator chart={chart} />}

        {isPersisted ? (
          <Form.ActionBar
            left={
              <a
                className="btn btn-danger"
                href={encounter_path(encounter.id)}
                data-method="delete"
                data-confirm="Are you sure?"
              >
                Delete Encounter
              </a>
            }
            right={
              <>
                <Form.CancelButton onClick={() => redirectTo(chart_encounters_path(encounter.chartId))} />
                <Form.SubmitButton label="Update Encounter" />
              </>
            }
          />
        ) : (
          <Form.ActionBar
            right={
              <>
                <Form.CancelButton onClick={() => redirectTo(chart_encounters_path(encounter.chartId))} />
                <Form.SubmitButton label="Create Encounter" />
              </>
            }
          />
        )}
      </div>
    </Form>
  )
}

EncounterForm.propTypes = {
  carePlan: types.carePlan,
  chart: types.chart.isRequired,
  charts: PropTypes.arrayOf(types.chart),
  isAdmin: PropTypes.bool.isRequired,
  encounter: types.encounter.isRequired,
  coreServiceTypes: PropTypes.arrayOf(types.selectOption).isRequired,
  clinicalEvents: PropTypes.arrayOf(PropTypes.object).isRequired,
  autoLockingDelayText: PropTypes.string,
  healthHomeServingChildrenSegments: PropTypes.arrayOf(types.segment).isRequired,
}

export default globalsStateWrapper(({ coreServiceTypes }) => ({ coreServiceTypes }))(EncounterForm)

export { segmentActiveAsOfDate }
